import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Fade from "react-reveal/Fade";
import Container from "common/components/UI/Container";
import Box from "common/components/Box";
import Heading from "common/components/Heading";
import BannerWrapper from "./banner.style";
import HomeImage from "common/assets/image/foodDelivery/home.gif";
import { useIsMobile } from "../../../hooks/useIsMobile";

const Banner = () => {
	const Data = useStaticQuery(graphql`
		query {
			foodDeliveryJson {
				BANNER_DATA {
					sectionImage {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
						}
					}
					title
					text
					tagLine
					buttons {
						icon {
							publicURL
						}
						title
						text
						link
					}
				}
			}
		}
	`);

	const { tagLine, buttons, title, text } = Data.foodDeliveryJson.BANNER_DATA;

	const [isMobile] = useIsMobile();

	const AppButtons = () => (
		<Box className="content">
			<Heading as="h2" content={title} />
			<Heading as="h2" content="Delivery Discounts" />

			{/* <Text as="p" className="paragraph" content={text} /> */}
			{/* <Text as="p" className="tagLine" content={tagLine} /> */}
			{/* <Box className="buttonWrap">
				{buttons.map(({ icon, text, title, link }, index) => (
					<Link className="bannerAppBtn" to={link} key={`banner-button-${index}`}>
						<Image src={icon.publicURL} width="30" height="30" alt={title} />
						<Box className="btnContent">
							<Text as="span" content={text} />
							<Text as="p" content={title} />
						</Box>
					</Link>
				))}
			</Box> */}
		</Box>
	);

	return (
		<BannerWrapper id="banner_section">
			<Container>
				<AppButtons />
				<Fade right>
					<div
						className="image"
						style={{
							backgroundImage: `url(${HomeImage})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							height: isMobile ? "100vw" : "30vw",
							width: "100%",
						}}
					></div>
				</Fade>

				{/* {isMobile && <AppButtons />} */}
			</Container>
		</BannerWrapper>
	);
};

export default Banner;
