import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Section = styled.section`
    padding-top: 120px;
    padding-bottom: 100px;
    @media (max-width: 1550px) {
        padding-top: 80px;
    }
    @media (max-width: 1199px) {
        padding-top: 40px;
    }
    @media (max-width: 768px) {
        padding-top: 30px;
    }
`;

export const ContentWrapper = styled.div`
    .masonryGrid {
        margin-left: -15px;
        margin-right: -15px;
    }
`;

export const BlogPost = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    cursor: pointer;
    width: calc(33.3333% - 0px);
    @media screen and (max-width: 768px) {
        width: calc(50% - 20px);
    }
    @media screen and (max-width: 480px) {
        width: 100%;
    }
    figure {
        margin: 0;
        img {
            border-radius: 5px;
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }
    p,
    div {
        color: #000 !important;
    }
    h4 {
        font-family: Arvo;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #0f2137;
        margin-bottom: 0;
        margin-top: 15px;
    }
    .learnMore {
        font-weight: 500;
        font-size: 15px;
        margin-top: 15px;
        display: inline-flex;
        align-items: center;
        color: ${themeGet("colors.linkColor")};
        i {
            line-height: 1;
            margin-left: 2px;
            transition: 0.3s ease 0s;
        }
        &:hover i {
            margin-left: 7px;
        }
    }
    p {
        min-height: 55px;
    }
    .blog-tags {
        position: absolute;
        top: 30px;
        left: 30px;
        .tag {
            padding: 6px 16px;
            background: #ac1f26;
            color: #fff;
            border-radius: 10px;
            margin-right: 10px;
        }
    }

    .blog-info {
        display: flex;
        opacity: 0.3 !important;
        justify-content: space-between;
        align-items: center;
        span {
            font-weight: bold;
        }
        i {
            margin-right: 5px;
        }
    }
`;

export default Section;
