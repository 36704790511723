import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Icon } from "react-icons-kit";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Box from "common/components/Box";
import AvailableRestaurantsWrapper from "./availableRestaurants.style";
const AvailableRestaurants = () => {
    const Data = useStaticQuery(graphql`
        query {
            foodDeliveryJson {
                AVAILABLE_RESTAURANTS_DATA {
                    title
                    desc
                }
            }
        }
    `);
    const { title, desc } = Data.foodDeliveryJson.AVAILABLE_RESTAURANTS_DATA;
    return (
        <AvailableRestaurantsWrapper id="restaurants">
            <Container>
                <Heading as="h2" content={title} />
                <div
                    style={{
                        display: "flex",
                        alignContent: "center",
                        textAlign: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <p
                        style={{
                            maxWidth: "500px",
                        }}
                    >
                        {desc}
                    </p>
                </div>
                <Box className="text-center">
                    <AnchorLink className="MoreButton" href="#blog">
                        Learn More <Icon size={14} icon={arrowRight} />
                    </AnchorLink>
                </Box>
            </Container>
        </AvailableRestaurantsWrapper>
    );
};

export default AvailableRestaurants;
